#site-footer{
    background: #17082d;
    color: #f8f8f8;
    a{
        color: #f8f8f8;
    }
    .pagetop{
        background: #0d031c;
        display: block;
        padding: 16px 0;
        text-align: center;
        color: #EEE;
    }
    .fnav{
        padding: 64px 0 0;
        text-align: center;
        @media screen and (max-width:980px){
            padding: 32px 20px;
        }
        li{
            list-style: none;
            display: inline-block;
            font-size: 13px;
            &:after{
                content: '|';
                opacity: .24;
                display: inline-block;
                margin: 0 24px;
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
        }
    }
    .copy{
        display: block;
        text-align: center;
        font-size: 10px;
        margin: 32px 0 0;
        padding: 0 0 64px;
        text-transform: uppercase;
        @media screen and (max-width:980px){
            margin: 0;
            padding: 0 0 32px;
        }
    }
}