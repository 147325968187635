@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700; 900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#252525;
$link:#D02;
$border:#eaeaea;

$main: #7c2de7;
$sub: #4ea7df;

@mixin grd(){
    background: $main;
    background: -moz-linear-gradient(-45deg,  $main 0%, $sub 100%);
    background: -webkit-linear-gradient(-45deg,  $main 0%,$sub 100%);
    background: linear-gradient(135deg,  $main 0%,$sub 100%);
}

@mixin oz(){
    font-family: 'Oswald', sans-serif;
}

/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:80px;
$sp-head:60px;


@media screen and (min-width:1121px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#fcfcfc //ボディ背景
	);
}

@media screen and (max-width:1120px){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#fcfcfc //ボディ背景
	);
}

@media screen and (max-width:980px){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#fcfcfc //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "page/firstview";
@import "page/index";

#site-wrapper,#site-footer{

}

.site-module{
    &.wrap{
        padding: 96px 0;
        @media screen and (max-width:1200px){
            padding: 64px 20px;
        }
        @media screen and (max-width:980px){
            padding: 32px 20px;   
        }
    }
    h2.large{
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 1;
        margin: 0 0 40px;
        @media screen and (max-width:980px){
            font-size: 24px;
            line-height: 1.5;
            margin: 0 0 24px;
        }
        strong{
            color: $main;
        }
        &.mb{
            margin: 0 0 22px;
        }
        &:before{
            content: attr(data-eng);
            color: $main;
            letter-spacing: .12em;
            display: block;
            font-size: 13px;
            margin: 0 0 20px;
            @media screen and (max-width:980px){
                font-size: 12px;
                margin: 0 0 8px;
            }
        }
    }
    .ispage{
        text-align: center;
        p{
            font-size: 16px;
            a{
                text-decoration: underline;
                color: $main;
            }
            &.eng{
                font-weight: 700;
                font-size: 40px;
                margin: 0 0 12px;
            }
        }
    }
    .form-style{
        width: 100%;
        border-collapse: collapse;
        @media screen and (max-width:980px){
            display: block;
        }
        tbody{
            @media screen and (max-width:980px){
                display: block;
            }
            tr{
                border-bottom: $border 1px solid;
                @media screen and (max-width:980px){
                    display: block;
                }
                &:last-child{
                    border: none;
                }
                @media screen and (min-width:981px){
                    &:first-child{
                        th,td{
                            padding-top: 0;
                        }
                    }
                }
                th,td{
                    vertical-align: middle;
                    text-align: left;
                    padding: 32px 0;
                    font-size: 15px;
                    @media screen and (max-width:980px){
                        display: block;
                        padding: 15px 0;
                    }
                }
                th{
                    width: 240px;
                    @media screen and (max-width:980px){
                        width: auto;
                        padding-bottom: 0;
                    }
                    &.hiss{
                        &:after{
                            content: '必須';
                            background: $main;
                            color: #FFF;
                            font-size: 12px;
                            padding: 4px 16px;
                            border-radius: 4px;
                            float: right;
                        }
                    }
                }
                td{
                    padding-left: 40px;
                    @media screen and (max-width:980px){
                        padding-left: 0;
                    }
                    input,
                    textarea{
                        @include font();
                        border: $border 1px solid;
                        border-radius: 4px;
                        width: 100%;
                        padding: 15px;
                        font-size: 14px;
                    }
                    textarea{
                        height: 160px;
                    }
                }
            }
        }
    }
    .formalert{
        text-align: center;
        margin: 24px 0 0;
        color: $main;
        font-size: 17px;
        font-weight: 700;
        border: $main 1px dashed;
        border-radius: 4px;
        background: #FFF;
        padding: 8px 0;
        @media screen and (max-width:980px){
            padding: 8px 20px;
            font-size: 14px;
        }
    }
    .send{
        text-align: center;
        input{
            @include grd();
            color: #FFF;
            padding: 16px 0;
            width: 320px;
            border: none;
            font-size: 20px;
            font-weight: 700;
            border-radius: 9999px;
            margin: 24px 0 0;
            cursor: pointer;
            @include transition(200ms);
            &:hover{
                width: 400px;
            }
        }
    }
}

#site-pager{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 48px;
    z-index: 999;
    @media screen and (max-width:1200px){
        right: 20px;
    }
    @media screen and (max-width:980px){
        display: none;
    }
    li{
        margin: 16px 0;
        a{
            width: 9px;
            height: 9px;
            display: block;
            background: $border;
            border-radius: 50%;
            list-style: none;
            overflow: hidden;
            text-indent: -9999px;
        }
        &.active{
            animation: scaling .6s;
            @keyframes scaling{
                0%{
                    transform: scale(1);
                }
                40%{
                    transform: scale(1.6);
                }
                100%{
                    transform: scale(1);
                }
            }
            a{
                background: $main;
            }
        }
    }
}

// USE ANIMATE
.scroll-anime{
	opacity: 0;
	transform: translateY(48px);
	@include transition(800ms);
	&._active{
		opacity: 1;
		transform: translateY(0);
	}
}

.scroll-img{
	position: relative;
	$ease-transition: cubic-bezier(0.86, 0, 0.07, 1);
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	&:before{
		background: $main;
		@include transition($speed , $delay:$speed , $ease:$ease-transition);
	}
	&:after{
		background: #FFF;
		@include transition($speed , $ease: $ease-transition);
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 600ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: scale(1.1);
}



#intro{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    &.active{
        &:before,
        &:after{
            height: 0;
        }
        #bar{
            animation: eve 400ms forwards;
            @keyframes eve{
                0%{
                    left: 0;
                }
                100%{
                    left:100%;
                }
            }
        }
    }
    &:before,
    &:after{
        content: '';
        width: 100%;
        height: 51%;
        position: absolute;
        background: #0d031c;
        left: 0;
        z-index: 1;
        @include transition(400ms);
    }
    &:before{
        top: 0;
    }
    &:after{
        bottom: 0;
    }
    #bar{
        height: 2px;
        position: fixed;
        top: 50%;
        left: 0;
        z-index: 2;
        transform: translateY(-50%);
        background: $main;
    }
}

.privacybox{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000,.88);
    z-index: 8000;
    @include flex();
    align-items: center;
    justify-content: center;
    .close{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        &:hover{
            &:before,
            &:after{
                transform: rotate(0deg) scale(.88);
            }
        }
        &:before,
        &:after{
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            top: 50%;
            left: 0;
            background: #FFF;
            transform: rotate(45deg);
            @include transition(200ms);
        }
        &:after{
            transform: rotate(-45deg);
        }
    }
    &__inner{
        width: 800px;
        max-height: 80vh;
        overflow: auto;
        padding: 56px;
        background: #FFF;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        h2{
            font-weight: 700;
            font-size: 26px;
            margin: 0 0 20px;
            padding: 0 0 8px;
            border-bottom: $normal 2px solid;
        }
        h3{
            font-weight: 700;
            font-size: 20px;
            margin: 0 0 8px;
        }
        p{
            text-align: justify;
        }
        ol{
            margin: 8px 0 0;
            li{
                list-style: none;
                position: relative;
                padding: 0 0 0 24px;
                margin: 0 0 2px;
                text-align: justify;
                &:last-child{
                    margin: 0;
                }
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                @for $i from 1 through 10{
                    &:nth-child(#{$i}){
                        &:before{
                            content: '(#{$i})'
                        }
                    }
                }
            }
        }
        .mb{
            margin-bottom: 32px;
        }
        .mmb{
            margin-bottom: 16px;
        }
    }    
}
