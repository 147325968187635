#site-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 45px 48px;
    z-index: 2000;
    @include flex();
    @media screen and (max-width:1200px){
        padding: 20px;
    }
    align-items: center;
    &.active{
        background: #FFF;
        position: fixed;
        padding-top: 16px;
        padding-bottom: 16px;
        animation: dn .6s;
        @keyframes dn{
            0%{
                transform: translateY(-100%);
            }
            100%{
                transform: translateY(0%);
            }
        }
        .logo{
            &:before{
                opacity: 1;
            }
            img{
                opacity: 0;
            }
        }
        .gnavi{
            ul{
                li{
                    a{
                        color: $normal;
                    }
                }
            }
        }
    }
    .logo{
        width: 102px;
        position: relative;
        transform: translateY(2px);
        @media screen and (max-width:980px){
            width: 96px;
        }
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("../../../images/common/logo_b_2x.png") no-repeat;
            background-size: 102px;
            opacity: 0;
            @media screen and (max-width:980px){
                background-size: 96px;
            }
        }
        img{
            width: 100%;
        }
    }
    .gnavi{
        ul{
            @include flex();
            align-items: center;
            li{
                margin: 0 64px 0 0;
                @media screen and (max-width:1200px){
                    margin: 0 32px 0 0;
                }
                @media screen and (max-width:980px){
                    display: none;
                }
                &:last-child{
                    margin: 0;
                }
                &.md{
                    margin: 0 32px 0 0;
                }
                &.contact{
                    @media screen and (max-width:980px){
                        display: block;   
                    }
                    a{
                        @include grd();
                        display: block;
                        padding: 10px 32px;
                        border-radius: 999px;
                        color: #FFF;
                        @media screen and (max-width:980px){
                            padding: 8px 24px;
                        }
                        i{
                            margin: 0 16px 0 0;
                            display: inline-block;
                        }
                    }
                }
                a{
                    font-size: 15px;
                    color: #FFF;
                    @media screen and (max-width:980px){
                        font-size: 14px;
                    }
                    &.tel{
                        line-height: 1;
                        font-size: 20px;
                        font-weight: 700;
                        &:before{
                            content: 'お電話でのお問い合わせ';
                            font-size: 11px;
                            display: block;
                            margin: 0 0 4px;
                            padding: 2px 0;
                        }
                    }
                }
            }
        }
    }
}