@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700; 900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 1121px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #252525;
    background: #fcfcfc;
    font-size: 14px;
    line-height: 1.8;
    font-family: 'Noto Sans JP', "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: 1120px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 1120px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #252525;
    background: #fcfcfc;
    font-size: 13px;
    line-height: 1.8;
    font-family: 'Noto Sans JP', "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 980px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #252525;
    background: #fcfcfc;
    font-size: 12px;
    line-height: 1.8;
    font-family: 'Noto Sans JP', "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 45px 48px;
  z-index: 2000;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    #site-header {
      padding: 20px; } }
  #site-header.active {
    background: #FFF;
    position: fixed;
    padding-top: 16px;
    padding-bottom: 16px;
    animation: dn .6s; }

@keyframes dn {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0%); } }
    #site-header.active .logo:before {
      opacity: 1; }
    #site-header.active .logo img {
      opacity: 0; }
    #site-header.active .gnavi ul li a {
      color: #252525; }
  #site-header .logo {
    width: 102px;
    position: relative;
    transform: translateY(2px); }
    @media screen and (max-width: 980px) {
      #site-header .logo {
        width: 96px; } }
    #site-header .logo:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../../images/common/logo_b_2x.png") no-repeat;
      background-size: 102px;
      opacity: 0; }
      @media screen and (max-width: 980px) {
        #site-header .logo:before {
          background-size: 96px; } }
    #site-header .logo img {
      width: 100%; }
  #site-header .gnavi ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    #site-header .gnavi ul li {
      margin: 0 64px 0 0; }
      @media screen and (max-width: 1200px) {
        #site-header .gnavi ul li {
          margin: 0 32px 0 0; } }
      @media screen and (max-width: 980px) {
        #site-header .gnavi ul li {
          display: none; } }
      #site-header .gnavi ul li:last-child {
        margin: 0; }
      #site-header .gnavi ul li.md {
        margin: 0 32px 0 0; }
      @media screen and (max-width: 980px) {
        #site-header .gnavi ul li.contact {
          display: block; } }
      #site-header .gnavi ul li.contact a {
        background: #7c2de7;
        background: -moz-linear-gradient(-45deg, #7c2de7 0%, #4ea7df 100%);
        background: -webkit-linear-gradient(-45deg, #7c2de7 0%, #4ea7df 100%);
        background: linear-gradient(135deg, #7c2de7 0%, #4ea7df 100%);
        display: block;
        padding: 10px 32px;
        border-radius: 999px;
        color: #FFF; }
        @media screen and (max-width: 980px) {
          #site-header .gnavi ul li.contact a {
            padding: 8px 24px; } }
        #site-header .gnavi ul li.contact a i {
          margin: 0 16px 0 0;
          display: inline-block; }
      #site-header .gnavi ul li a {
        font-size: 15px;
        color: #FFF; }
        @media screen and (max-width: 980px) {
          #site-header .gnavi ul li a {
            font-size: 14px; } }
        #site-header .gnavi ul li a.tel {
          line-height: 1;
          font-size: 20px;
          font-weight: 700; }
          #site-header .gnavi ul li a.tel:before {
            content: 'お電話でのお問い合わせ';
            font-size: 11px;
            display: block;
            margin: 0 0 4px;
            padding: 2px 0; }

#site-footer {
  background: #17082d;
  color: #f8f8f8; }
  #site-footer a {
    color: #f8f8f8; }
  #site-footer .pagetop {
    background: #0d031c;
    display: block;
    padding: 16px 0;
    text-align: center;
    color: #EEE; }
  #site-footer .fnav {
    padding: 64px 0 0;
    text-align: center; }
    @media screen and (max-width: 980px) {
      #site-footer .fnav {
        padding: 32px 20px; } }
    #site-footer .fnav li {
      list-style: none;
      display: inline-block;
      font-size: 13px; }
      #site-footer .fnav li:after {
        content: '|';
        opacity: .24;
        display: inline-block;
        margin: 0 24px; }
      #site-footer .fnav li:last-child:after {
        display: none; }
  #site-footer .copy {
    display: block;
    text-align: center;
    font-size: 10px;
    margin: 32px 0 0;
    padding: 0 0 64px;
    text-transform: uppercase; }
    @media screen and (max-width: 980px) {
      #site-footer .copy {
        margin: 0;
        padding: 0 0 32px; } }

.site-index .firstview {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  color: #f2f2f2; }
  .site-index .firstview:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: .12; }
  .site-index .firstview:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 255px rgba(0, 0, 0, 0.4);
    z-index: 2; }
  .site-index .firstview.active .firstview__bg:before {
    transform: scale(1);
    -webkit-transition: all 8000ms 0ms linear;
    -moz-transition: all 8000ms 0ms linear;
    -ms-transition: all 8000ms 0ms linear;
    -o-transition: all 8000ms 0ms linear;
    transition: all 8000ms 0ms linear; }
  .site-index .firstview.active .firstview__left_conner {
    top: 0;
    transition: top 600ms 200ms; }
  .site-index .firstview.active .firstview__right_conner {
    bottom: 0;
    transition: bottom 600ms 200ms; }
  .site-index .firstview.active .firstview__line__border {
    animation: mov 600ms 200ms forwards; }

@keyframes mov {
  0% {
    height: 0;
    opacity: 1; }
  100% {
    height: 32vh;
    opacity: .64; } }
  .site-index .firstview #video {
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: #000; }
  .site-index .firstview__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .site-index .firstview__bg:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: url("../../../images/index/firstview.jpg") no-repeat center;
      background-size: cover;
      transform: scale(1.2); }
    .site-index .firstview__bg:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
      opacity: .04; }
  .site-index .firstview__left_conner {
    position: absolute;
    top: -100px;
    left: 0;
    background: url("../../../images/index/left_conner.png") no-repeat;
    background-size: cover;
    width: 550px;
    height: 595px;
    z-index: 1;
    transform: translateX(-100%) translateY(-100%); }
    @media screen and (max-width: 980px) {
      .site-index .firstview__left_conner {
        width: 240px;
        height: 260px; } }
  .site-index .firstview__right_conner {
    position: absolute;
    bottom: -100px;
    right: 0;
    background: url("../../../images/index/right_conner.png") no-repeat;
    background-size: cover;
    width: 425px;
    height: 465px;
    z-index: 1;
    transform: translateX(100%) translateY(100%); }
    @media screen and (max-width: 980px) {
      .site-index .firstview__right_conner {
        width: 320px;
        height: 350px; } }
  .site-index .firstview__scroll {
    position: absolute;
    right: 48px;
    bottom: 0;
    z-index: 3;
    height: 90px; }
    @media screen and (max-width: 1200px) {
      .site-index .firstview__scroll {
        right: 20px; } }
    .site-index .firstview__scroll:before {
      position: absolute;
      right: 0;
      bottom: 0;
      content: '';
      width: 1px;
      height: 100%;
      background: #FFF;
      animation: sc 2s infinite; }

@keyframes sc {
  0% {
    height: 0%;
    bottom: 100%; }
  25% {
    height: 100%;
    bottom: 0; }
  51% {
    height: 100%;
    bottom: 0; }
  75% {
    height: 0%;
    bottom: 0; }
  100% {
    height: 0%;
    bottom: 0; } }
    .site-index .firstview__scroll:after {
      font-size: 11px;
      -webkit-writing-mode: vertical-rl;
      -moz-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      white-space: nowrap;
      letter-spacing: .12em;
      content: 'SCROLL';
      position: absolute;
      top: 0;
      right: 8px;
      color: #FFF; }
  .site-index .firstview__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2; }
    .site-index .firstview__line__border {
      position: absolute;
      width: 1px;
      height: 32vh;
      transform-origin: top right;
      transform: rotate(43deg);
      background: #7c2de7;
      opacity: .64; }
      .site-index .firstview__line__border--left {
        top: 48px;
        left: 42%; }
      .site-index .firstview__line__border--center {
        transform-origin: bottom left;
        bottom: 44px;
        left: 32%; }
      .site-index .firstview__line__border--right {
        transform-origin: bottom right;
        bottom: 12%;
        right: 32%; }
  .site-index .firstview__title {
    margin: 0 0 0 10%;
    position: relative;
    z-index: 3;
    top: 20px; }
    @media screen and (max-width: 980px) {
      .site-index .firstview__title {
        margin: 0 0 0 20px; } }
    .site-index .firstview__title > dt {
      font-size: 28px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: .02en; }
      @media screen and (max-width: 980px) {
        .site-index .firstview__title > dt {
          font-size: 20px; } }
      .site-index .firstview__title > dt strong {
        font-size: 68px;
        display: block;
        margin: 20px 0 0;
        text-indent: -.02em; }
        @media screen and (max-width: 980px) {
          .site-index .firstview__title > dt strong {
            font-size: 32px;
            margin: 12px 0 0; } }
    .site-index .firstview__title > dd {
      margin: 36px 0 0; }
      @media screen and (max-width: 980px) {
        .site-index .firstview__title > dd {
          margin: 24px 0 0; } }
      .site-index .firstview__title > dd a {
        background: rgba(0, 0, 0, 0.08);
        border: #FFF 1px solid;
        border-radius: 999px;
        display: inline-block;
        padding: 10px 40px;
        font-size: 16px;
        color: #f2f2f2;
        font-weight: 700;
        margin: 0 20px 0 0; }
        @media screen and (max-width: 980px) {
          .site-index .firstview__title > dd a {
            font-size: 14px;
            padding: 8px 24px;
            width: 60%;
            text-align: center;
            margin: 0 0 12px; }
            .site-index .firstview__title > dd a:last-of-type {
              margin: 0; } }
        .site-index .firstview__title > dd a i {
          font-size: 12px;
          display: inline-block;
          margin: 0 0 0 12px; }
        .site-index .firstview__title > dd a:last-of-type {
          margin: 0; }
  .site-index .firstview__copy {
    position: absolute;
    left: 50px;
    bottom: 40px;
    font-size: 10px;
    z-index: 3; }
    @media screen and (max-width: 1200px) {
      .site-index .firstview__copy {
        left: 20px; } }
    @media screen and (max-width: 980px) {
      .site-index .firstview__copy {
        bottom: 20px; } }

.site-index .context {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 64px; }
  @media screen and (max-width: 980px) {
    .site-index .context {
      margin: 0 0 32px;
      font-size: 14px; } }

.site-index .firstsec {
  position: relative;
  overflow: hidden; }
  .site-index .firstsec .bg {
    position: absolute;
    top: 0;
    right: 0;
    background: url("../../../images/index/right_conner_sec.png") no-repeat;
    background-size: cover;
    width: 425px;
    height: 465px;
    z-index: 1; }
    @media screen and (max-width: 980px) {
      .site-index .firstsec .bg {
        width: 140px;
        height: 153px;
        z-index: -1; } }
  .site-index .firstsec__list {
    position: relative;
    margin: 0 0 72px; }
    .site-index .firstsec__list:last-of-type {
      margin: 0; }
    .site-index .firstsec__list__eng {
      position: absolute;
      left: -4%;
      bottom: -80px;
      font-size: 320px;
      line-height: 1;
      color: #f4f3f6;
      z-index: -1;
      pointer-events: none;
      font-weight: 900; }
    .site-index .firstsec__list .display {
      padding: 0 0 32px; }
    .site-index .firstsec__list h3 {
      font-size: 18px;
      color: #FFF;
      background: #7c2de7;
      width: 380px;
      padding: 8px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(0%);
      opacity: 0;
      z-index: 2;
      text-align: center;
      -webkit-transition: all 600ms 0ms ease-in-out;
      -moz-transition: all 600ms 0ms ease-in-out;
      -ms-transition: all 600ms 0ms ease-in-out;
      -o-transition: all 600ms 0ms ease-in-out;
      transition: all 600ms 0ms ease-in-out; }
      .site-index .firstsec__list h3._active {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%); }
    .site-index .firstsec__list__items {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      .site-index .firstsec__list__items__price {
        font-size: 16px;
        text-align: left;
        padding: 0 20px; }
        .site-index .firstsec__list__items__price:before {
          content: '価格';
          color: #FFF;
          display: inline-block;
          background: #7c2de7;
          padding: 0 16px;
          border-radius: 4px;
          margin: 0 14px 0 0;
          transform: translateY(-6px); }
          @media screen and (max-width: 980px) {
            .site-index .firstsec__list__items__price:before {
              font-size: 13px;
              transform: translateY(-4px); } }
        .site-index .firstsec__list__items__price strong {
          font-size: 32px;
          line-height: 1;
          display: inline-block;
          margin: 0 4px 0 0; }
          @media screen and (max-width: 980px) {
            .site-index .firstsec__list__items__price strong {
              font-size: 24px; } }
      .site-index .firstsec__list__items__mes {
        font-weight: 500;
        font-size: 13px; }
      .site-index .firstsec__list__items li {
        width: 31%;
        background: #FFF;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
        position: relative; }
        @media screen and (max-width: 980px) {
          .site-index .firstsec__list__items li {
            width: auto;
            margin: 0 0 24px; }
            .site-index .firstsec__list__items li:last-child {
              margin: 0; } }
        .site-index .firstsec__list__items li:after {
          content: attr(data-txt);
          position: absolute;
          top: -16px;
          left: -16px;
          background: #7c2de7;
          color: #FFF;
          width: 72px;
          height: 72px;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: 700;
          border-radius: 50%; }
          @media screen and (max-width: 980px) {
            .site-index .firstsec__list__items li:after {
              width: 64px;
              height: 64px;
              font-size: 15px;
              top: -12px;
              left: -12px; } }
        .site-index .firstsec__list__items li figure img {
          width: 100%; }
        .site-index .firstsec__list__items li dl {
          padding: 16px 0;
          font-weight: 700;
          text-align: center; }
          @media screen and (max-width: 980px) {
            .site-index .firstsec__list__items li dl {
              padding: 14px 0; } }
          .site-index .firstsec__list__items li dl dt {
            font-size: 20px;
            font-weight: 700;
            line-height: 1;
            margin: 4px 0 18px;
            padding: 0 0 20px;
            border-bottom: #eaeaea 1px solid; }
            @media screen and (max-width: 980px) {
              .site-index .firstsec__list__items li dl dt {
                margin: 4px 0 16px;
                padding: 0 0 16px; } }

.site-index .secondsec {
  background: #17082d;
  color: #f8f8f8; }
  .site-index .secondsec__list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 980px) {
      .site-index .secondsec__list {
        display: block; } }
    .site-index .secondsec__list._active li {
      transform: translateY(0);
      opacity: 1; }
    .site-index .secondsec__list__number {
      font-family: 'Oswald', sans-serif;
      position: absolute;
      top: 0;
      left: 16px;
      font-size: 72px;
      line-height: 1;
      color: #7c2de7;
      transform: translateY(-50%); }
      @media screen and (max-width: 980px) {
        .site-index .secondsec__list__number {
          font-size: 56px; } }
    .site-index .secondsec__list li {
      margin: 32px 0 0;
      width: 30%;
      border: rgba(124, 45, 231, 0.24) 1px solid;
      padding: 56px 0 24px;
      position: relative;
      transform: translateY(40px);
      opacity: 0; }
      @media screen and (max-width: 980px) {
        .site-index .secondsec__list li {
          width: auto;
          padding: 40px 0 20px; } }
      .site-index .secondsec__list li:nth-child(1) {
        -webkit-transition: all 400ms 120ms ease-in-out;
        -moz-transition: all 400ms 120ms ease-in-out;
        -ms-transition: all 400ms 120ms ease-in-out;
        -o-transition: all 400ms 120ms ease-in-out;
        transition: all 400ms 120ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(2) {
        -webkit-transition: all 400ms 240ms ease-in-out;
        -moz-transition: all 400ms 240ms ease-in-out;
        -ms-transition: all 400ms 240ms ease-in-out;
        -o-transition: all 400ms 240ms ease-in-out;
        transition: all 400ms 240ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(3) {
        -webkit-transition: all 400ms 360ms ease-in-out;
        -moz-transition: all 400ms 360ms ease-in-out;
        -ms-transition: all 400ms 360ms ease-in-out;
        -o-transition: all 400ms 360ms ease-in-out;
        transition: all 400ms 360ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(4) {
        -webkit-transition: all 400ms 480ms ease-in-out;
        -moz-transition: all 400ms 480ms ease-in-out;
        -ms-transition: all 400ms 480ms ease-in-out;
        -o-transition: all 400ms 480ms ease-in-out;
        transition: all 400ms 480ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(5) {
        -webkit-transition: all 400ms 600ms ease-in-out;
        -moz-transition: all 400ms 600ms ease-in-out;
        -ms-transition: all 400ms 600ms ease-in-out;
        -o-transition: all 400ms 600ms ease-in-out;
        transition: all 400ms 600ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(6) {
        -webkit-transition: all 400ms 720ms ease-in-out;
        -moz-transition: all 400ms 720ms ease-in-out;
        -ms-transition: all 400ms 720ms ease-in-out;
        -o-transition: all 400ms 720ms ease-in-out;
        transition: all 400ms 720ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(7) {
        -webkit-transition: all 400ms 840ms ease-in-out;
        -moz-transition: all 400ms 840ms ease-in-out;
        -ms-transition: all 400ms 840ms ease-in-out;
        -o-transition: all 400ms 840ms ease-in-out;
        transition: all 400ms 840ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(8) {
        -webkit-transition: all 400ms 960ms ease-in-out;
        -moz-transition: all 400ms 960ms ease-in-out;
        -ms-transition: all 400ms 960ms ease-in-out;
        -o-transition: all 400ms 960ms ease-in-out;
        transition: all 400ms 960ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(9) {
        -webkit-transition: all 400ms 1080ms ease-in-out;
        -moz-transition: all 400ms 1080ms ease-in-out;
        -ms-transition: all 400ms 1080ms ease-in-out;
        -o-transition: all 400ms 1080ms ease-in-out;
        transition: all 400ms 1080ms ease-in-out; }
      .site-index .secondsec__list li:nth-child(10) {
        -webkit-transition: all 400ms 1200ms ease-in-out;
        -moz-transition: all 400ms 1200ms ease-in-out;
        -ms-transition: all 400ms 1200ms ease-in-out;
        -o-transition: all 400ms 1200ms ease-in-out;
        transition: all 400ms 1200ms ease-in-out; }
      .site-index .secondsec__list li figure {
        text-align: center; }
        .site-index .secondsec__list li figure img {
          width: 40%; }
          @media screen and (max-width: 980px) {
            .site-index .secondsec__list li figure img {
              width: 30%; } }
        .site-index .secondsec__list li figure figcaption {
          margin: 24px 0 0;
          font-size: 16px; }
          @media screen and (max-width: 980px) {
            .site-index .secondsec__list li figure figcaption {
              font-size: 15px;
              margin: 20px 0 0; } }

.site-index .thirdsec {
  background: #0d031c;
  color: #f8f8f8; }
  .site-index .thirdsec h2.large {
    font-size: 24px;
    color: #EEE; }
  .site-index .thirdsec figure {
    padding: 24px 0 0; }
    .site-index .thirdsec figure img {
      width: 100%; }
  .site-index .thirdsec__list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    .site-index .thirdsec__list li {
      width: 20%;
      padding: 0 15px;
      margin: 20px 0 0; }
      @media screen and (max-width: 980px) {
        .site-index .thirdsec__list li {
          width: 50%; } }
      .site-index .thirdsec__list li img {
        width: 100%; }

.site-index .foursec__list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 980px) {
    .site-index .foursec__list {
      display: block; } }
  .site-index .foursec__list._active li {
    opacity: 1;
    transform: translateY(0); }
    .site-index .foursec__list._active li:before {
      transform: scale(1) rotate(0deg); }
    .site-index .foursec__list._active li i {
      transform: scale(1); }
  .site-index .foursec__list li {
    list-style: none;
    width: 31%;
    padding: 15px;
    background: #f3f3f3;
    border-radius: 6px;
    margin: 32px 0 0;
    position: relative;
    opacity: 0;
    transform: translateY(40px); }
    @media screen and (max-width: 980px) {
      .site-index .foursec__list li {
        width: auto;
        margin: 0 0 20px; }
        .site-index .foursec__list li:last-child {
          margin: 0; } }
    .site-index .foursec__list li:nth-child(1) {
      -webkit-transition: all 400ms 120ms ease-in-out;
      -moz-transition: all 400ms 120ms ease-in-out;
      -ms-transition: all 400ms 120ms ease-in-out;
      -o-transition: all 400ms 120ms ease-in-out;
      transition: all 400ms 120ms ease-in-out; }
      .site-index .foursec__list li:nth-child(1) i {
        -webkit-transition: all 400ms 320ms ease-in-out;
        -moz-transition: all 400ms 320ms ease-in-out;
        -ms-transition: all 400ms 320ms ease-in-out;
        -o-transition: all 400ms 320ms ease-in-out;
        transition: all 400ms 320ms ease-in-out; }
      .site-index .foursec__list li:nth-child(1):before {
        content: "1";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 320ms ease-in-out;
        -moz-transition: all 400ms 320ms ease-in-out;
        -ms-transition: all 400ms 320ms ease-in-out;
        -o-transition: all 400ms 320ms ease-in-out;
        transition: all 400ms 320ms ease-in-out; }
    .site-index .foursec__list li:nth-child(2) {
      -webkit-transition: all 400ms 240ms ease-in-out;
      -moz-transition: all 400ms 240ms ease-in-out;
      -ms-transition: all 400ms 240ms ease-in-out;
      -o-transition: all 400ms 240ms ease-in-out;
      transition: all 400ms 240ms ease-in-out; }
      .site-index .foursec__list li:nth-child(2) i {
        -webkit-transition: all 400ms 440ms ease-in-out;
        -moz-transition: all 400ms 440ms ease-in-out;
        -ms-transition: all 400ms 440ms ease-in-out;
        -o-transition: all 400ms 440ms ease-in-out;
        transition: all 400ms 440ms ease-in-out; }
      .site-index .foursec__list li:nth-child(2):before {
        content: "2";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 440ms ease-in-out;
        -moz-transition: all 400ms 440ms ease-in-out;
        -ms-transition: all 400ms 440ms ease-in-out;
        -o-transition: all 400ms 440ms ease-in-out;
        transition: all 400ms 440ms ease-in-out; }
    .site-index .foursec__list li:nth-child(3) {
      -webkit-transition: all 400ms 360ms ease-in-out;
      -moz-transition: all 400ms 360ms ease-in-out;
      -ms-transition: all 400ms 360ms ease-in-out;
      -o-transition: all 400ms 360ms ease-in-out;
      transition: all 400ms 360ms ease-in-out; }
      .site-index .foursec__list li:nth-child(3) i {
        -webkit-transition: all 400ms 560ms ease-in-out;
        -moz-transition: all 400ms 560ms ease-in-out;
        -ms-transition: all 400ms 560ms ease-in-out;
        -o-transition: all 400ms 560ms ease-in-out;
        transition: all 400ms 560ms ease-in-out; }
      .site-index .foursec__list li:nth-child(3):before {
        content: "3";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 560ms ease-in-out;
        -moz-transition: all 400ms 560ms ease-in-out;
        -ms-transition: all 400ms 560ms ease-in-out;
        -o-transition: all 400ms 560ms ease-in-out;
        transition: all 400ms 560ms ease-in-out; }
    .site-index .foursec__list li:nth-child(4) {
      -webkit-transition: all 400ms 480ms ease-in-out;
      -moz-transition: all 400ms 480ms ease-in-out;
      -ms-transition: all 400ms 480ms ease-in-out;
      -o-transition: all 400ms 480ms ease-in-out;
      transition: all 400ms 480ms ease-in-out; }
      .site-index .foursec__list li:nth-child(4) i {
        -webkit-transition: all 400ms 680ms ease-in-out;
        -moz-transition: all 400ms 680ms ease-in-out;
        -ms-transition: all 400ms 680ms ease-in-out;
        -o-transition: all 400ms 680ms ease-in-out;
        transition: all 400ms 680ms ease-in-out; }
      .site-index .foursec__list li:nth-child(4):before {
        content: "4";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 680ms ease-in-out;
        -moz-transition: all 400ms 680ms ease-in-out;
        -ms-transition: all 400ms 680ms ease-in-out;
        -o-transition: all 400ms 680ms ease-in-out;
        transition: all 400ms 680ms ease-in-out; }
    .site-index .foursec__list li:nth-child(5) {
      -webkit-transition: all 400ms 600ms ease-in-out;
      -moz-transition: all 400ms 600ms ease-in-out;
      -ms-transition: all 400ms 600ms ease-in-out;
      -o-transition: all 400ms 600ms ease-in-out;
      transition: all 400ms 600ms ease-in-out; }
      .site-index .foursec__list li:nth-child(5) i {
        -webkit-transition: all 400ms 800ms ease-in-out;
        -moz-transition: all 400ms 800ms ease-in-out;
        -ms-transition: all 400ms 800ms ease-in-out;
        -o-transition: all 400ms 800ms ease-in-out;
        transition: all 400ms 800ms ease-in-out; }
      .site-index .foursec__list li:nth-child(5):before {
        content: "5";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 800ms ease-in-out;
        -moz-transition: all 400ms 800ms ease-in-out;
        -ms-transition: all 400ms 800ms ease-in-out;
        -o-transition: all 400ms 800ms ease-in-out;
        transition: all 400ms 800ms ease-in-out; }
    .site-index .foursec__list li:nth-child(6) {
      -webkit-transition: all 400ms 720ms ease-in-out;
      -moz-transition: all 400ms 720ms ease-in-out;
      -ms-transition: all 400ms 720ms ease-in-out;
      -o-transition: all 400ms 720ms ease-in-out;
      transition: all 400ms 720ms ease-in-out; }
      .site-index .foursec__list li:nth-child(6) i {
        -webkit-transition: all 400ms 920ms ease-in-out;
        -moz-transition: all 400ms 920ms ease-in-out;
        -ms-transition: all 400ms 920ms ease-in-out;
        -o-transition: all 400ms 920ms ease-in-out;
        transition: all 400ms 920ms ease-in-out; }
      .site-index .foursec__list li:nth-child(6):before {
        content: "6";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 920ms ease-in-out;
        -moz-transition: all 400ms 920ms ease-in-out;
        -ms-transition: all 400ms 920ms ease-in-out;
        -o-transition: all 400ms 920ms ease-in-out;
        transition: all 400ms 920ms ease-in-out; }
    .site-index .foursec__list li:nth-child(7) {
      -webkit-transition: all 400ms 840ms ease-in-out;
      -moz-transition: all 400ms 840ms ease-in-out;
      -ms-transition: all 400ms 840ms ease-in-out;
      -o-transition: all 400ms 840ms ease-in-out;
      transition: all 400ms 840ms ease-in-out; }
      .site-index .foursec__list li:nth-child(7) i {
        -webkit-transition: all 400ms 1040ms ease-in-out;
        -moz-transition: all 400ms 1040ms ease-in-out;
        -ms-transition: all 400ms 1040ms ease-in-out;
        -o-transition: all 400ms 1040ms ease-in-out;
        transition: all 400ms 1040ms ease-in-out; }
      .site-index .foursec__list li:nth-child(7):before {
        content: "7";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 1040ms ease-in-out;
        -moz-transition: all 400ms 1040ms ease-in-out;
        -ms-transition: all 400ms 1040ms ease-in-out;
        -o-transition: all 400ms 1040ms ease-in-out;
        transition: all 400ms 1040ms ease-in-out; }
    .site-index .foursec__list li:nth-child(8) {
      -webkit-transition: all 400ms 960ms ease-in-out;
      -moz-transition: all 400ms 960ms ease-in-out;
      -ms-transition: all 400ms 960ms ease-in-out;
      -o-transition: all 400ms 960ms ease-in-out;
      transition: all 400ms 960ms ease-in-out; }
      .site-index .foursec__list li:nth-child(8) i {
        -webkit-transition: all 400ms 1160ms ease-in-out;
        -moz-transition: all 400ms 1160ms ease-in-out;
        -ms-transition: all 400ms 1160ms ease-in-out;
        -o-transition: all 400ms 1160ms ease-in-out;
        transition: all 400ms 1160ms ease-in-out; }
      .site-index .foursec__list li:nth-child(8):before {
        content: "8";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 1160ms ease-in-out;
        -moz-transition: all 400ms 1160ms ease-in-out;
        -ms-transition: all 400ms 1160ms ease-in-out;
        -o-transition: all 400ms 1160ms ease-in-out;
        transition: all 400ms 1160ms ease-in-out; }
    .site-index .foursec__list li:nth-child(9) {
      -webkit-transition: all 400ms 1080ms ease-in-out;
      -moz-transition: all 400ms 1080ms ease-in-out;
      -ms-transition: all 400ms 1080ms ease-in-out;
      -o-transition: all 400ms 1080ms ease-in-out;
      transition: all 400ms 1080ms ease-in-out; }
      .site-index .foursec__list li:nth-child(9) i {
        -webkit-transition: all 400ms 1280ms ease-in-out;
        -moz-transition: all 400ms 1280ms ease-in-out;
        -ms-transition: all 400ms 1280ms ease-in-out;
        -o-transition: all 400ms 1280ms ease-in-out;
        transition: all 400ms 1280ms ease-in-out; }
      .site-index .foursec__list li:nth-child(9):before {
        content: "9";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 1280ms ease-in-out;
        -moz-transition: all 400ms 1280ms ease-in-out;
        -ms-transition: all 400ms 1280ms ease-in-out;
        -o-transition: all 400ms 1280ms ease-in-out;
        transition: all 400ms 1280ms ease-in-out; }
    .site-index .foursec__list li:nth-child(10) {
      -webkit-transition: all 400ms 1200ms ease-in-out;
      -moz-transition: all 400ms 1200ms ease-in-out;
      -ms-transition: all 400ms 1200ms ease-in-out;
      -o-transition: all 400ms 1200ms ease-in-out;
      transition: all 400ms 1200ms ease-in-out; }
      .site-index .foursec__list li:nth-child(10) i {
        -webkit-transition: all 400ms 1400ms ease-in-out;
        -moz-transition: all 400ms 1400ms ease-in-out;
        -ms-transition: all 400ms 1400ms ease-in-out;
        -o-transition: all 400ms 1400ms ease-in-out;
        transition: all 400ms 1400ms ease-in-out; }
      .site-index .foursec__list li:nth-child(10):before {
        content: "10";
        position: absolute;
        top: -8px;
        left: -8px;
        font-size: 18px;
        z-index: 2;
        background: #7c2de7;
        color: #FFF;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-transition: all 400ms 1400ms ease-in-out;
        -moz-transition: all 400ms 1400ms ease-in-out;
        -ms-transition: all 400ms 1400ms ease-in-out;
        -o-transition: all 400ms 1400ms ease-in-out;
        transition: all 400ms 1400ms ease-in-out; }
    .site-index .foursec__list li:before {
      transform: scale(0) rotate(60deg); }
    .site-index .foursec__list li:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 0 12px 16px;
      border-color: transparent transparent transparent #252525;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -28px;
      opacity: .04; }
      @media screen and (max-width: 980px) {
        .site-index .foursec__list li:after {
          display: none; } }
    @media screen and (min-width: 981px) {
      .site-index .foursec__list li:nth-child(-n+3) {
        margin: 0; }
      .site-index .foursec__list li:nth-child(3n+3):after {
        display: none; } }
    .site-index .foursec__list li i {
      font-size: 40px;
      color: #7c2de7;
      display: block;
      text-align: center;
      padding: 16px 0 0;
      transform: scale(0); }
    .site-index .foursec__list li dl {
      margin: 16px 0 0; }
      .site-index .foursec__list li dl dt {
        font-size: 17px;
        font-weight: 700;
        text-align: center;
        margin: 0 0 6px; }
      .site-index .foursec__list li dl dd {
        margin: 0 15px; }

.site-index .fifthsec {
  background: #f5f5f5; }
  .site-index .fifthsec__list li {
    background: #FFF;
    margin: 0 0 24px;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.04);
    cursor: pointer; }
    .site-index .fifthsec__list li:hover {
      box-shadow: 6px 6px 32px rgba(124, 45, 231, 0.12); }
    .site-index .fifthsec__list li:last-child {
      margin: 0; }
    .site-index .fifthsec__list li dl dt {
      padding: 20px;
      font-size: 17px;
      padding-left: 56px;
      padding-right: 56px; }
      @media screen and (max-width: 980px) {
        .site-index .fifthsec__list li dl dt {
          font-size: 15px; } }
      .site-index .fifthsec__list li dl dt i {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        opacity: .24;
        font-size: 20px; }
    .site-index .fifthsec__list li dl dt, .site-index .fifthsec__list li dl dd {
      position: relative; }
      .site-index .fifthsec__list li dl dt:before, .site-index .fifthsec__list li dl dd:before {
        content: 'Q';
        color: #FFF;
        background: #7c2de7;
        font-size: 14px;
        width: 26px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        position: absolute;
        top: 24px;
        left: 20px;
        font-weight: 500;
        border-radius: 50%; }
    .site-index .fifthsec__list li dl dd {
      padding: 20px;
      padding-left: 56px;
      font-size: 15px;
      border-top: #f5f5f5 2px solid; }
      @media screen and (max-width: 980px) {
        .site-index .fifthsec__list li dl dd {
          font-size: 13px; } }
      .site-index .fifthsec__list li dl dd:before {
        content: 'A';
        top: 22px; }

.site-index .sixsec .context {
  line-height: 1.9;
  font-size: 15px;
  font-weight: 500; }
  .site-index .sixsec .context a {
    color: #7c2de7;
    display: inline-block;
    margin: 0 4px; }
    .site-index .sixsec .context a i {
      display: inline-block;
      margin: 0 0 0 4px;
      font-size: 10px;
      transform: translateY(-1px); }

.site-module.wrap {
  padding: 96px 0; }
  @media screen and (max-width: 1200px) {
    .site-module.wrap {
      padding: 64px 20px; } }
  @media screen and (max-width: 980px) {
    .site-module.wrap {
      padding: 32px 20px; } }

.site-module h2.large {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  margin: 0 0 40px; }
  @media screen and (max-width: 980px) {
    .site-module h2.large {
      font-size: 24px;
      line-height: 1.5;
      margin: 0 0 24px; } }
  .site-module h2.large strong {
    color: #7c2de7; }
  .site-module h2.large.mb {
    margin: 0 0 22px; }
  .site-module h2.large:before {
    content: attr(data-eng);
    color: #7c2de7;
    letter-spacing: .12em;
    display: block;
    font-size: 13px;
    margin: 0 0 20px; }
    @media screen and (max-width: 980px) {
      .site-module h2.large:before {
        font-size: 12px;
        margin: 0 0 8px; } }

.site-module .ispage {
  text-align: center; }
  .site-module .ispage p {
    font-size: 16px; }
    .site-module .ispage p a {
      text-decoration: underline;
      color: #7c2de7; }
    .site-module .ispage p.eng {
      font-weight: 700;
      font-size: 40px;
      margin: 0 0 12px; }

.site-module .form-style {
  width: 100%;
  border-collapse: collapse; }
  @media screen and (max-width: 980px) {
    .site-module .form-style {
      display: block; } }
  @media screen and (max-width: 980px) {
    .site-module .form-style tbody {
      display: block; } }
  .site-module .form-style tbody tr {
    border-bottom: #eaeaea 1px solid; }
    @media screen and (max-width: 980px) {
      .site-module .form-style tbody tr {
        display: block; } }
    .site-module .form-style tbody tr:last-child {
      border: none; }
    @media screen and (min-width: 981px) {
      .site-module .form-style tbody tr:first-child th, .site-module .form-style tbody tr:first-child td {
        padding-top: 0; } }
    .site-module .form-style tbody tr th, .site-module .form-style tbody tr td {
      vertical-align: middle;
      text-align: left;
      padding: 32px 0;
      font-size: 15px; }
      @media screen and (max-width: 980px) {
        .site-module .form-style tbody tr th, .site-module .form-style tbody tr td {
          display: block;
          padding: 15px 0; } }
    .site-module .form-style tbody tr th {
      width: 240px; }
      @media screen and (max-width: 980px) {
        .site-module .form-style tbody tr th {
          width: auto;
          padding-bottom: 0; } }
      .site-module .form-style tbody tr th.hiss:after {
        content: '必須';
        background: #7c2de7;
        color: #FFF;
        font-size: 12px;
        padding: 4px 16px;
        border-radius: 4px;
        float: right; }
    .site-module .form-style tbody tr td {
      padding-left: 40px; }
      @media screen and (max-width: 980px) {
        .site-module .form-style tbody tr td {
          padding-left: 0; } }
      .site-module .form-style tbody tr td input,
      .site-module .form-style tbody tr td textarea {
        font-family: 'Noto Sans JP', "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.024em;
        border: #eaeaea 1px solid;
        border-radius: 4px;
        width: 100%;
        padding: 15px;
        font-size: 14px; }
      .site-module .form-style tbody tr td textarea {
        height: 160px; }

.site-module .formalert {
  text-align: center;
  margin: 24px 0 0;
  color: #7c2de7;
  font-size: 17px;
  font-weight: 700;
  border: #7c2de7 1px dashed;
  border-radius: 4px;
  background: #FFF;
  padding: 8px 0; }
  @media screen and (max-width: 980px) {
    .site-module .formalert {
      padding: 8px 20px;
      font-size: 14px; } }

.site-module .send {
  text-align: center; }
  .site-module .send input {
    background: #7c2de7;
    background: -moz-linear-gradient(-45deg, #7c2de7 0%, #4ea7df 100%);
    background: -webkit-linear-gradient(-45deg, #7c2de7 0%, #4ea7df 100%);
    background: linear-gradient(135deg, #7c2de7 0%, #4ea7df 100%);
    color: #FFF;
    padding: 16px 0;
    width: 320px;
    border: none;
    font-size: 20px;
    font-weight: 700;
    border-radius: 9999px;
    margin: 24px 0 0;
    cursor: pointer;
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }
    .site-module .send input:hover {
      width: 400px; }

#site-pager {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 48px;
  z-index: 999; }
  @media screen and (max-width: 1200px) {
    #site-pager {
      right: 20px; } }
  @media screen and (max-width: 980px) {
    #site-pager {
      display: none; } }
  #site-pager li {
    margin: 16px 0; }
    #site-pager li a {
      width: 9px;
      height: 9px;
      display: block;
      background: #eaeaea;
      border-radius: 50%;
      list-style: none;
      overflow: hidden;
      text-indent: -9999px; }
    #site-pager li.active {
      animation: scaling .6s; }

@keyframes scaling {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(1.6); }
  100% {
    transform: scale(1); } }
      #site-pager li.active a {
        background: #7c2de7; }

.scroll-anime {
  opacity: 0;
  transform: translateY(48px);
  -webkit-transition: all 800ms 0ms ease-in-out;
  -moz-transition: all 800ms 0ms ease-in-out;
  -ms-transition: all 800ms 0ms ease-in-out;
  -o-transition: all 800ms 0ms ease-in-out;
  transition: all 800ms 0ms ease-in-out; }
  .scroll-anime._active {
    opacity: 1;
    transform: translateY(0); }

.scroll-img {
  position: relative; }
  .scroll-img:before, .scroll-img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .scroll-img:before {
    background: #7c2de7;
    -webkit-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img:after {
    background: #FFF;
    -webkit-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img.__active:before {
    width: 0; }
  .scroll-img.__active:after {
    width: 0; }

.scale-enter-active, .scale-leave-active {
  transition: 600ms; }

.scale-enter, .scale-leave-to {
  opacity: 0;
  transform: scale(1.1); }

#intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; }
  #intro.active:before, #intro.active:after {
    height: 0; }
  #intro.active #bar {
    animation: eve 400ms forwards; }

@keyframes eve {
  0% {
    left: 0; }
  100% {
    left: 100%; } }
  #intro:before, #intro:after {
    content: '';
    width: 100%;
    height: 51%;
    position: absolute;
    background: #0d031c;
    left: 0;
    z-index: 1;
    -webkit-transition: all 400ms 0ms ease-in-out;
    -moz-transition: all 400ms 0ms ease-in-out;
    -ms-transition: all 400ms 0ms ease-in-out;
    -o-transition: all 400ms 0ms ease-in-out;
    transition: all 400ms 0ms ease-in-out; }
  #intro:before {
    top: 0; }
  #intro:after {
    bottom: 0; }
  #intro #bar {
    height: 2px;
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 2;
    transform: translateY(-50%);
    background: #7c2de7; }

.privacybox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.88);
  z-index: 8000;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center; }
  .privacybox .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer; }
    .privacybox .close:hover:before, .privacybox .close:hover:after {
      transform: rotate(0deg) scale(0.88); }
    .privacybox .close:before, .privacybox .close:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      background: #FFF;
      transform: rotate(45deg);
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
    .privacybox .close:after {
      transform: rotate(-45deg); }
  .privacybox__inner {
    width: 800px;
    max-height: 80vh;
    overflow: auto;
    padding: 56px;
    background: #FFF;
    font-size: 13px;
    font-weight: 400;
    position: relative; }
    .privacybox__inner h2 {
      font-weight: 700;
      font-size: 26px;
      margin: 0 0 20px;
      padding: 0 0 8px;
      border-bottom: #252525 2px solid; }
    .privacybox__inner h3 {
      font-weight: 700;
      font-size: 20px;
      margin: 0 0 8px; }
    .privacybox__inner p {
      text-align: justify; }
    .privacybox__inner ol {
      margin: 8px 0 0; }
      .privacybox__inner ol li {
        list-style: none;
        position: relative;
        padding: 0 0 0 24px;
        margin: 0 0 2px;
        text-align: justify; }
        .privacybox__inner ol li:last-child {
          margin: 0; }
        .privacybox__inner ol li:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0; }
        .privacybox__inner ol li:nth-child(1):before {
          content: "(1)"; }
        .privacybox__inner ol li:nth-child(2):before {
          content: "(2)"; }
        .privacybox__inner ol li:nth-child(3):before {
          content: "(3)"; }
        .privacybox__inner ol li:nth-child(4):before {
          content: "(4)"; }
        .privacybox__inner ol li:nth-child(5):before {
          content: "(5)"; }
        .privacybox__inner ol li:nth-child(6):before {
          content: "(6)"; }
        .privacybox__inner ol li:nth-child(7):before {
          content: "(7)"; }
        .privacybox__inner ol li:nth-child(8):before {
          content: "(8)"; }
        .privacybox__inner ol li:nth-child(9):before {
          content: "(9)"; }
        .privacybox__inner ol li:nth-child(10):before {
          content: "(10)"; }
    .privacybox__inner .mb {
      margin-bottom: 32px; }
    .privacybox__inner .mmb {
      margin-bottom: 16px; }
