.site-index {
    .context{
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        line-height: 1;
        margin: 0 0 64px;
        @media screen and (max-width:980px){
            margin: 0 0 32px;
            font-size: 14px;
        }
    }
    .firstsec{
        position: relative;
        overflow: hidden;
        .bg{
            position: absolute;
            top: 0;
            right: 0;
            background: url("../../../images/index/right_conner_sec.png") no-repeat;
            background-size: cover;
            width: 425px;
            height: 465px;
            z-index: 1;
            @media screen and (max-width:980px){
                width: 140px;
                height: 153px;
                z-index: -1;
            }
        }
        &__list{
            position: relative;
            margin: 0 0 72px;
            &:last-of-type{
                margin: 0;
            }
            &__eng{
                position: absolute;
                left: -4%;
                bottom: -80px;
                font-size: 320px;
                line-height: 1;
                color: #f4f3f6;
                z-index: -1;
                pointer-events: none;
                font-weight: 900;
            }
            .display{
                padding: 0 0 32px;
            }
            h3{
                font-size: 18px;
                color: #FFF;
                background: $main;
                width: 380px;
                padding: 8px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%) translateY(0%);
                opacity: 0;
                z-index: 2;
                text-align: center;
                @include transition(600ms);
                &._active{
                    opacity: 1;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
            &__items{
                @include flex();
                &__price{
                    font-size: 16px;
                    text-align: left;
                    padding: 0 20px;
                    &:before{
                        content: '価格';
                        color: #FFF;
                        display: inline-block;
                        background: $main;
                        padding: 0 16px;
                        border-radius: 4px;
                        margin: 0 14px 0 0;
                        transform: translateY(-6px);
                        @media screen and (max-width:980px){
                            font-size: 13px;
                            transform: translateY(-4px);
                        }
                    }
                    strong{
                        font-size: 32px;
                        line-height: 1;
                        display: inline-block;
                        margin: 0 4px 0 0;
                        @media screen and (max-width:980px){
                            font-size: 24px;   
                        }
                    }
                }
                &__mes{
                    font-weight: 500;
                    font-size: 13px;
                }
                li{
                    width: 31%;
                    background: #FFF;
                    box-shadow: 0 8px 32px rgba(#000,.08);
                    position: relative;
                    @media screen and (max-width:980px){
                        width: auto;
                        margin: 0 0 24px;
                        &:last-child{
                            margin: 0;
                        }
                    }
                    &:after{
                        content: attr(data-txt);
                        position: absolute;
                        top: -16px;
                        left: -16px;
                        background: $main;
                        color: #FFF;
                        width: 72px;
                        height: 72px;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        font-weight: 700;
                        border-radius: 50%;
                        @media screen and (max-width:980px){
                            width: 64px;
                            height: 64px;
                            font-size: 15px;
                            top: -12px;
                            left: -12px;
                        }
                    }
                    figure{
                        img{
                            width: 100%;
                        }
                    }
                    dl{
                        padding: 16px 0;
                        font-weight: 700;
                        text-align: center;
                        @media screen and (max-width:980px){
                            padding: 14px 0;   
                        }
                        dt{
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 1;
                            margin: 4px 0 18px;
                            padding: 0 0 20px;
                            border-bottom: $border 1px solid;
                            @media screen and (max-width:980px){
                                margin: 4px 0 16px;
                                padding: 0 0 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .secondsec{
        background: #17082d;
        color: #f8f8f8;
        &__list{
            @include flex();
            @media screen and (max-width:980px){
                display: block;   
            }
            &._active{
                li{
                    transform: translateY(0);
                    opacity: 1;
                }
            }
            &__number{
                @include oz();
                position: absolute;
                top: 0;
                left: 16px;
                font-size: 72px;
                line-height: 1;
                color: $main;
                transform: translateY(-50%);
                @media screen and (max-width:980px){
                    font-size: 56px;   
                }
            }
            li{
                margin: 32px 0 0;
                width: 30%;
                border: rgba($main,.24) 1px solid;
                padding: 56px 0 24px;
                position: relative;
                transform: translateY(40px);
                opacity:0;
                @media screen and (max-width:980px){
                    width: auto;
                    padding: 40px 0 20px;
                }
                @for $i from 1 through 10{
                    &:nth-child(#{$i}){
                        @include transition(400ms,$delay:120ms * $i);
                    }
                }
                figure{
                    text-align: center;
                    img{
                        width: 40%;
                        @media screen and (max-width:980px){
                            width: 30%;    
                        }
                    }
                    figcaption{
                        margin: 24px 0 0;
                        font-size: 16px;
                        @media screen and (max-width:980px){
                            font-size: 15px;
                            margin: 20px 0 0;
                        }
                    }
                }
            }
        }
    }
    
    .thirdsec{
        background: #0d031c;
        color: #f8f8f8;
        h2.large{
            font-size: 24px;
            color: #EEE;
        }
        figure{
            padding: 24px 0 0;
            img{
                width: 100%;
            }
        }
        &__list{
            @include flex();
            li{
                width: 20%;
                padding: 0 15px;
                margin: 20px 0 0;
                @media screen and (max-width:980px){
                    width: 50%;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    
    .foursec{
        &__list{
            @include flex();
            @media screen and (max-width:980px){
                display: block;   
            }
            &._active{
                li{
                    opacity: 1;
                    transform: translateY(0);
                    &:before{
                        transform: scale(1) rotate(0deg);
                    }
                    i{
                        transform: scale(1);
                    }
                }
            }
            li{
                list-style: none;
                width: 31%;
                padding: 15px;
                background: #f3f3f3;
                border-radius: 6px;
                margin: 32px 0 0;
                position: relative;
                opacity: 0;
                transform: translateY(40px);
                @media screen and (max-width:980px){
                    width: auto;
                    margin: 0 0 20px;
                    &:last-child{
                        margin: 0;
                    }
                }
                @for $i from 1 through 10{
                    &:nth-child(#{$i}){
                        @include transition(400ms, $delay: 120ms * $i);
                        i{
                            @include transition(400ms, $delay: 120ms * $i + 200ms);
                        }
                        &:before{
                            content: '#{$i}';
                            position: absolute;
                            top: -8px;
                            left: -8px;
                            font-size: 18px;
                            z-index: 2;
                            background: $main;
                            color: #FFF;
                            $size: 40px;
                            width: $size;
                            height: $size;
                            @include flex();
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            @include transition(400ms, $delay: 120ms * $i + 200ms);
                        }       
                    }
                }
                &:before{
                    transform: scale(0) rotate(60deg);
                }
                &:after{
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 0 12px 16px;
                    border-color: transparent transparent transparent $normal;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -28px;
                    opacity: .04;
                    @media screen and (max-width:980px){
                        display: none;
                    }
                }
                @media screen and (min-width:981px){
                    &:nth-child(-n+3){
                        margin: 0;
                    }
                    &:nth-child(3n+3){
                        &:after{
                            display: none;
                        }
                    }
                }
                i{
                    font-size: 40px;
                    color: $main;
                    display: block;
                    text-align: center;
                    padding: 16px 0 0;
                    transform: scale(0);
                }
                dl{
                    margin: 16px 0 0;
                    dt{
                        font-size: 17px;
                        font-weight: 700;
                        text-align: center;
                        margin: 0 0 6px;
                    }
                    dd{
                        margin: 0 15px;
                    }
                }
            }
        }
    }
    
    .fifthsec{
        background: #f5f5f5;
        &__list{
            li{
                background: #FFF;
                margin: 0 0 24px;
                box-shadow: 6px 6px 24px rgba(#000,.04);
                cursor: pointer;
                &:hover{
                    box-shadow: 6px 6px 32px rgba($main,.12);
                }
                &:last-child{
                    margin: 0;
                }
                dl{
                    dt{
                        padding: 20px;
                        font-size: 17px;
                        padding-left: 56px;
                        padding-right: 56px;
                        @media screen and (max-width:980px){
                            font-size: 15px;
                        }
                        i{
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: translateY(-50%);
                            opacity: .24;
                            font-size: 20px;
                        }
                    }
                    dt,dd{
                        position: relative;
                        &:before{
                            content: 'Q';
                            color: #FFF;
                            background: $main;
                            font-size: 14px;
                            width: 26px;
                            height: 26px;
                            line-height: 26px;
                            text-align: center;
                            position: absolute;
                            top: 24px;
                            left: 20px;
                            font-weight: 500;
                            border-radius: 50%;
                        }
                    }
                    dd{
                        padding: 20px;
                        padding-left: 56px;
                        font-size: 15px;
                        border-top: #f5f5f5 2px solid;
                        @media screen and (max-width:980px){
                            font-size: 13px;
                        }
                        &:before{
                            content: 'A';
                            top: 22px;
                        }
                    }
                }
            }
        }
    }
    
    .sixsec{
        .context{
            line-height: 1.9;
            font-size: 15px;
            font-weight: 500;
            a{
                color: $main;
                display: inline-block;
                margin: 0 4px;
                i{
                    display: inline-block;
                    margin: 0 0 0 4px;
                    font-size: 10px;
                    transform: translateY(-1px);
                }
            }
        }
    }
}