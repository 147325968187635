.site-index {
    .firstview{
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        @include flex();
        align-items: center;
        justify-content: flex-start;
        color: #f2f2f2;
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            opacity: .12;
        }
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: inset 0 0 255px rgba(#000,.4);
            z-index: 2;
        }
        &.active{
            .firstview__bg{
                &:before{
                    transform: scale(1);
                    @include transition(8000ms, $ease:linear);
                }
            }
            .firstview__left_conner{
                top: 0;
                transition: top 600ms 200ms;
            }
            .firstview__right_conner{
                bottom: 0;
                transition: bottom 600ms 200ms;
            }
            .firstview__line__border{
                animation: mov 600ms 200ms forwards;
                @keyframes mov{
                    0%{
                        height: 0;
                        opacity: 1;
                    }
                    100%{
                        height: 32vh;
                        opacity: .64;
                    }
                }
            }
        }
        #video{
            position: absolute;
            top: 0;
            left: 0;
            -o-object-fit: cover;
            object-fit: cover;
            width: 100vw;
            height: 100vh;
            z-index: -1;
            background: #000;
        }
        &__bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &:before{
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 100%;
                background: url("../../../images/index/firstview.jpg") no-repeat center;
                background-size: cover;
                transform: scale(1.2);
            }
            &:after{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #000;
                opacity: .04;
            }
        }
        &__left_conner{
            position: absolute;
            top: -100px;
            left: 0;
            background: url("../../../images/index/left_conner.png") no-repeat;
            background-size: cover;
            width: 550px;
            height: 595px;
            z-index: 1;
            transform: translateX(-100%) translateY(-100%);
            @media screen and (max-width:980px){
                width: 240px;
                height: 260px;
            }
        }
        &__right_conner{
            position: absolute;
            bottom: -100px;
            right: 0;
            background: url("../../../images/index/right_conner.png") no-repeat;
            background-size: cover;
            width: 425px;
            height: 465px;
            z-index: 1;
            transform: translateX(100%) translateY(100%);
            @media screen and (max-width:980px){
                width: 320px;
                height: 350px;
            }
        }
        &__scroll{
            position: absolute;
            right: 48px;
            bottom: 0;
            z-index: 3;
            height: 90px;
            @media screen and (max-width:1200px){
                right: 20px;   
            }
            &:before{
                position: absolute;
                right: 0;
                bottom: 0;
                content: '';
                width: 1px;
                height: 100%;
                background: #FFF;
                //opacity: .4;
                animation: sc 2s infinite;
                @keyframes sc{
                    0%{
                        height: 0%;
                        bottom: 100%;
                    }
                   25%{
                        height: 100%;
                        bottom: 0;
                    }
                    51%{
                        height: 100%;
                        bottom: 0;
                    }
                    75%{
                        height: 0%;
                        bottom: 0;
                    }
                    100%{
                        height: 0%;
                        bottom: 0;
                    }
                }
            }
            &:after{
                font-size: 11px;
                -webkit-writing-mode: vertical-rl;
                -moz-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                white-space: nowrap;
                letter-spacing: .12em;
                content: 'SCROLL';
                position: absolute;
                top: 0;
                right: 8px;
                color: #FFF;
            }
        }
        &__line{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 2;
            &__border{
                position: absolute;
                width: 1px;
                height: 32vh;
                transform-origin: top right;
                transform: rotate(43deg);
                background: $main;
                opacity: .64;
                &--left{
                    top: 48px;
                    left: 42%;
                }
                &--center{
                    transform-origin: bottom left;
                    bottom: 44px;
                    left: 32%;
                }
                &--right{
                    transform-origin: bottom right;
                    bottom: 12%;
                    right: 32%;
                }
            }
        }
        &__title{
            margin: 0 0 0 10%;
            position: relative;
            z-index: 3;
            top: 20px;
            @media screen and (max-width:980px){
                margin: 0 0 0 20px;
            }
            > dt{
                font-size: 28px;
                line-height: 1;
                font-weight: 500;
                letter-spacing: .02en;
                @media screen and (max-width:980px){
                    font-size: 20px;
                }
                strong{
                    font-size: 68px;
                    display: block;
                    margin: 20px 0 0;
                    text-indent: -.02em;
                    @media screen and (max-width:980px){
                        font-size: 32px;
                        margin: 12px 0 0;
                    }
                }
            }
            >dd{
                margin: 36px 0 0;
                @media screen and (max-width:980px){
                    margin: 24px 0 0;
                }
                a{
                    background: rgba(#000,.08);
                    border: #FFF 1px solid;
                    border-radius: 999px;
                    display: inline-block;
                    padding: 10px 40px;
                    font-size: 16px;
                    color: #f2f2f2;
                    font-weight: 700;
                    margin: 0 20px 0 0;
                    @media screen and (max-width:980px){
                        font-size: 14px;
                        padding: 8px 24px;
                        width: 60%;
                        text-align: center;
                        margin: 0 0 12px;
                        &:last-of-type{
                            margin: 0;
                        }
                    }
                    i{
                        font-size: 12px;
                        display: inline-block;
                        margin: 0 0 0 12px;
                    }
                    &:last-of-type{
                        margin: 0;
                    }
                }
            }
        }
        &__copy{
            position: absolute;
            left: 50px;
            bottom: 40px;
            font-size: 10px;
            z-index: 3;
            @media screen and (max-width:1200px){
                left: 20px; 
            }
            @media screen and (max-width:980px){
                bottom: 20px;   
            }
        }
    }
}